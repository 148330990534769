<template>
  <b-form-group
    label="Bitiş Saati"
    label-for="etime"
  >
    <validation-provider
      #default="{ errors }"
      name="Bitiş Saati"
      rules="required"
    >
      <b-form-timepicker
        id="etime"
        v-model="itemData.etime"
        locale="tr"
        placeholder="Bitiş Saati"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormTimepicker } from 'bootstrap-vue'

export default {
  name: 'EndDate',
  components: {
    BFormGroup,
    BFormTimepicker,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>
