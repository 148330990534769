<template>
  <b-form-group
    label="Başlangıç Saati"
    label-for="stime"
  >
    <validation-provider
      #default="{ errors }"
      name="Başlangıç Saati"
      rules="required"
    >
      <b-form-timepicker
        id="stime"
        v-model="itemData.stime"
        locale="tr"
        placeholder="Başlangıç Saati"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, BFormTimepicker } from 'bootstrap-vue'

export default {
  name: 'StartDate',
  components: {
    BFormGroup,
    BFormTimepicker,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>
