<template>
  <b-card>
    <yandex-map
      :coords="itemData.maps"
      :zoom="10"
      ymap-class="height-500"
      :settings="settings"
      @click="onClick"
    >
      <ymap-marker
        :coords="itemData.maps"
        marker-id="map"
        hint-content="Konum"
      />
    </yandex-map>
    <b-alert
      class="mt-2"
      variant="primary"
      show
    >
      <div class="alert-body text-center">
        <p><FeatherIcon icon="InfoIcon" /> Haritada bir konum arayın veya bir yer işaretleyin. Bulunduğunuz konumu bulmak için sol köşedeki <FeatherIcon icon="NavigationIcon" /> iconuna tıklayın ve konumunuzu işaretleyin.</p>
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
  name: 'LocationMap',
  components: {
    BCard,
    BAlert,
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      settings: {
        apiKey: 'f3a66eb6-eceb-4711-b078-deb41a508fe1',
        lang: 'tr_TR',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
  methods: {
    onClick(e) {
      this.itemData.maps = e.get('coords')
    },
  },
}
</script>
