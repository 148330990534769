<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="itemData.title"
              placeholder="Başlık"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <start-time />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <end-time />
        </b-col>
        <b-col>
          <b-form-group
            label="Telefon"
            label-for="phone"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+9)
              </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="itemData.phone"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="Telefon"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Fax"
            label-for="fax"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+9)
              </b-input-group-prepend>
              <cleave
                id="fax"
                v-model="itemData.fax"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="Telefon"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Mobile"
            label-for="mobile"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+9)
              </b-input-group-prepend>
              <cleave
                id="mobile"
                v-model="itemData.mobile"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="Telefon"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="E-Posta"
            label-for="email"
          >
            <b-form-input
              v-model="itemData.email"
              placeholder="E-Posta"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Adres"
            label-for="address"
          >
            <b-form-textarea
              v-model="itemData.address"
              placeholder="Adres"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Yol Tarifi Bağlantısı"
            label-for="direction_url"
          >
            <b-form-input
              v-model="itemData.direction_url"
              placeholder="Yol Tarifi Bağlantısı"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <location-map />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BRow, BInputGroup, BInputGroupPrepend, BFormTextarea,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import LocationMap from '@/views/Admin/Contacts/LocationMap.vue'
import EndTime from '@/views/Admin/Contacts/elements/EndTime.vue'
import StartTime from '@/views/Admin/Contacts/elements/StartTime.vue'

export default {
  name: 'ContactForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    Cleave,
    LocationMap,
    EndTime,
    StartTime,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>

<style scoped>

</style>
